import { InjectionToken } from '@angular/core';
import { PopupsService } from './popups.service';

type IListener = (event: MouseEvent) => void;

interface IPopupsServiceInterface {
  runClickListener(listener: IListener): void;
  removeClickListener(listener: IListener): void;
  setUniqueID(uniqueClass: string, id: string): void;
  getUniqueID(id: string): string;
  removeUniqueID(id: string): void;
}

const POPUPS_SERVICE = new InjectionToken<PopupsService>('PopupsService');

export { POPUPS_SERVICE, IPopupsServiceInterface, IListener };
